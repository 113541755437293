@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-topicspath {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
  padding: 0; }
  @media screen and (max-width: 1168px) {
    .c-topicspath {
      padding: 0 15px; } }
  @media screen and (max-width: 568px) {
    .c-topicspath {
      bottom: 8px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 12px;
    color: #888888; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li > a {
      color: #888888; }

.l-lowerCaption {
  width: 100%;
  background-image: url("/inc/image/common/bg_lowerCaption.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  height: 230px;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .l-lowerCaption__inner {
    max-width: 1138px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px; }
  .l-lowerCaption__title {
    font-weight: bold;
    color: #333;
    padding: 0;
    width: 100%;
    font-size: 32px; }
    .l-lowerCaption__title small {
      font-size: 18px;
      font-weight: 600;
      color: #002D99;
      display: block;
      letter-spacing: 0.06em;
      text-transform: uppercase; }
